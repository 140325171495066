.home {
  background-color: #2a2a72;
  background-image: linear-gradient(45deg, #2a2a72 0%, #009ffd 100%);

  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.home__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.home__title {
  color: white;
  position: absolute;
  top: 10%;
  font-size: x-large;
}

.home__text {
  position: absolute;
  color: white;
  font-size: 1.3rem;
  padding: 1px;
  right: 45%;
  top: 27%;
  max-width: 50%;
}

.home__button {
  position: absolute;
  font-size: 1.5rem;
  font-weight: bold;
  top: 55.5%;
  left: 14%;
}
.home__button button {
  background-color: #6b0f1a;
  background-image: linear-gradient(315deg, #6b0f1a 0%, #b91372 74%);
  border-radius: 15px;
  border: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 4rem;
  padding: 0 1.6rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.home__button button:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: 0.1s;
  transform: translateY(-5px); /* Move the button up by 5 pixels */
  box-shadow: 0 0 10px 5px #ffffff;
  transition-duration: 0.3s;
}

.image-wrapper {
  position: absolute;
  top: 210px;
  right: 170px;
  width: 368px;
  height: auto;
  max-width: 100%;
  border-radius: 20px;
  transition: opacity 0.5s ease-in-out;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .home__text {
    right: 10%;
    top: 35%;
    max-width: 80%;
  }

  .image-wrapper {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 80%;
    max-width: 400px;
  }
}
