.philosophy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 80vh;
}

.stats-text {
  margin-left: 235px;
  margin-top: 50px;
  font-size: 1rem;
}

.stats__img {
  display: flex;

  margin-left: 135px;
  margin-top: 50px;
}

.stats-image {
  max-width: 50%;
  height: auto;
}

.philosophy__text--header {
position: absolute;
margin-left: 900px;
margin-top: 53px;
}

.philosophy__text {
  position: absolute;
  margin-left: 745px;
  margin-top: 180px;
  font-size: 1.5rem;
  padding-right: 60px;
}
