.offer {
  background-color: #2a2a72;
  background-image: linear-gradient(45deg, #2a2a72 0%, #009ffd 100%);

  height: 80vh;
  display: flex;
  align-items: flex-start;
}

.offer__content {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.offer__title {
  color: white;
  position: absolute;
  font-size: x-large;
  align-items: center;
  margin-left: 550px;
  margin-top: 20px;
}

.offer__intro {
  color: white;
  position: absolute;
  margin-left: 297px;
  margin-top: 127px;
  font-size: 1rem;
}

.offer__paragraph {
  color: white;
  position: absolute;
  margin-right: 250px;
  margin-top: 200px;
  font-size: 1.2rem;
  padding-left: 5%;
  padding-right: 30%;
}

.contact__button {
  position: absolute;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 310px;
  margin-top: 495px;
}
.contact__button button {
  background-color: #6b0f1a;
  background-image: linear-gradient(315deg, #6b0f1a 0%, #b91372 74%);
  border-radius: 15px;
  border: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 4rem;
  padding: 0 1.6rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.contact__button button:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: 0.1s;
  transform: translateY(-5px); /* Move the button up by 5 pixels */
  box-shadow: 0 0 10px 5px #ffffff;
  transition-duration: 0.3s;
}

.image-wrapper-offer {
  position: absolute;
  margin-top: 195px;
  margin-left: 850px;
  width: 500px;
  height: auto;
  max-width: 100%;
  border-radius: 30px;
  transition: opacity 0.5s ease-in-out;
}

.image-wrapper-offer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px; /* Also add border-radius to the image itself for consistent curved borders */
}
