.contact {
  height: 80vh;
}
.contact__header {
  margin-top: 50px;
}
h3 {
  font-weight: bold;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card {
  width: 250px;
  height: 150px;
  margin-bottom: 20px;
  margin-left: 325px;
  margin-top: 10px;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card__header {
  display: flex;
  justify-content: center;
}

.card__header h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-content .MuiTypography-body2 {
  margin-bottom: 8px;
}

.contact__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

@media (min-width: 600px) {
  .contact__form {
    width: 60%; /* Add this to occupy half of the screen on larger screens */
    margin-right: 100px;
  }
}
